import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import TagImageWebsite from '../../assets/images/WEBSITE Home Page Tagline white Feb 2024.png'
import './HeroBanner.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { isMP4 } from '../../services/validations'
import { moveMp4ToFront, mp4Index } from './HeroBanner.utils'
import { HeroBannerPropTypes, HeroBannerTypes } from './HeroBanner.types'

const PropertySearch = loadable(
  () => import('../PropertySearch/PropertySearch'),
)

const HeroBanner = ({
  featuredList,
  SearchIsStick,
  setStick,
}: HeroBannerPropTypes): ReactElement => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const sliderRef = useRef<Slider>(null)
  const videoRef: any = useRef(null)
  const featuredItems: HeroBannerTypes[] = moveMp4ToFront(
    featuredList.length ? featuredList : [],
  )

  const handleVideoPlay = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause() // Pause the slider when video plays
    }
  }

  const handleVideoEnd = () => {
    if (sliderRef.current) {
      videoRef.current.currentTime = 0
      sliderRef.current.slickPlay() // Resume autoplay after the video ends
      sliderRef.current.slickNext() // Manually move to the next slide
    }
  }

  const handleAfterChange = (currentIndex: number) => {
    // Assuming the video is on the first slide (index 0), modify as needed
    if (currentIndex === mp4Index) {
      if (videoRef.current) {
        videoRef.current.currentTime = 0 // Reset the video to the beginning
        videoRef.current.play() // Manually play the video
      }
    }
  }

  if (!featuredItems) {
    return <></>
  }

  const displaySlide = (item: HeroBannerTypes): JSX.Element => {
    if (
      item?.featuredImage?.includes('mp4') ||
      item?.featuredImage?.includes('video')
    ) {
      return (
        <video
          ref={videoRef}
          className="hero-banner-slider-video"
          key={item?.featuredImage}
          autoPlay
          muted
          playsInline
          onPlay={handleVideoPlay}
          onEnded={handleVideoEnd}
          controls={false}
        >
          <source
            src={
              isMobile && item?.gallery
                ? item?.gallery[0]?.url
                : item?.featuredImage
            }
            type="video/mp4"
          />
        </video>
      )
    }

    useEffect(() => {
      // This will only run in the browser
      const handleResize = () => {
        setIsMobile(window.innerWidth < 450)
      }

      // Check the window size when the component mounts
      handleResize()

      // Add event listener to update the state on window resize
      window.addEventListener('resize', handleResize)

      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', handleResize)
    }, [])

    if (item?.optimizedFeaturedImg) {
      return (
        <GatsbyImage
          className="banner-slider-image"
          image={getImage(item?.optimizedFeaturedImg)!}
          alt="banner slide image"
        />
      )
    }

    if (item?.featuredImage) {
      return (
        <LazyLoadImage
          className="banner-slider-image"
          loading="lazy"
          src={item.featuredImage}
          alt={item.slug}
        />
      )
    }

    return (
      <LazyLoadImage
        loading="lazy"
        src="https://placehold.co/600x400/EEE/FFFFFF?font=open-sans&text=Property%20Img"
      />
    )
  }

  return (
    <div className="hero-banner">
      <Slider
        swipeToSlide={false}
        arrows={false}
        dots={false}
        fade
        easing="ease"
        className="hero-banner-slider"
        autoplay
        infinite
        ref={sliderRef}
        afterChange={(prop) => {
          handleAfterChange(prop)
        }}
      >
        {featuredItems.map((item, idx) => displaySlide(item))}
      </Slider>

      <div className="hero-banner-content">
        <div className="hero-banner-content__main">
          <div className="hero-banner-content__main--logo">
            <img
              src={TagImageWebsite}
              className="hero-banner-content__main--logo--desktop"
              alt="logo"
            />
          </div>

          <div className="hero-banner-content__main--text">
            Experience inspired living that is easy, distinct, and enriching.
            Celebrate life everyday in our condo and house and lot communities
            across the Philippines.
          </div>

          <div className="hero-banner-content__main--link">
            <Link
              to="/about-us"
              className="hero-banner-content__main--link-btn"
            >
              Learn More
            </Link>
          </div>

          <div className="hero-banner-content__search">
            <PropertySearch
              display
              isStick={SearchIsStick}
              variant="sticky"
              onSearchClose={setStick}
              showHeaderText={false}
            />
          </div>
        </div>
      </div>

      <div className="hero-banner__search--mobile">
        <PropertySearch
          display
          isStick={SearchIsStick}
          variant="sticky"
          onSearchClose={setStick}
          showHeaderText={false}
        />
      </div>
    </div>
  )
}

export default HeroBanner
