import React from 'react'
import './DigitalCounter.scss'
import AnimatedCounter from '../AnimatedCounter/AnimatedCounter'
import { motion } from 'framer-motion'

type DigitalCounterProps = {
  inView?: boolean
}

function DigitalCounter({ inView }: DigitalCounterProps): JSX.Element {
  const showTextTime = 0.935
  return (
    <div className="digital-counter">
      <div className="digital-counter__content">
        <div className="digital-counter__content-counter">
          <motion.div
            initial={inView ? { opacity: 0, y: 300 } : {}}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: showTextTime }}
            className="digital-counter__content-counter--locations"
          >
            <h4 className="text-sub">Over</h4>
            <h2>
              <AnimatedCounter
                from={0}
                to={80000}
                styles="digital-counter__content-counter--locations-count counter-main"
              />
              {/*
               <span className="digital-counter__content-counter--locations-text text-main">
                Thousand
              </span> */}
            </h2>
            <h4 className="text-sub">Homes built</h4>
          </motion.div>

          <motion.div
            initial={inView ? { opacity: 0, y: -500 } : {}}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: showTextTime }}
            className="digital-counter__content-counter--projects"
          >
            <h2>
              <AnimatedCounter
                from={0}
                to={10}
                styles="digital-counter__content-counter--projects-count counter-main"
              />
              <AnimatedCounter
                from={0}
                to={3}
                styles="digital-counter__content-counter--projects-count counter-main"
              />

              {/* <span className="digital-counter__content-counter--projects-text text-main">
              Thousand
              </span> */}
            </h2>
            <h4 className="text-sub">Developments</h4>
          </motion.div>

          <motion.div
            initial={inView ? { opacity: 0, y: 300 } : {}}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: showTextTime }}
            className="digital-counter__content-counter--units"
          >
            <h4 className="text-sub">In</h4>
            <h2>
              <AnimatedCounter
                from={0}
                to={3}
                styles="digital-counter__content-counter--units-count counter-main"
              />
              <AnimatedCounter
                from={0}
                to={6}
                styles="digital-counter__content-counter--units-count counter-main"
              />
              {/* <span className="digital-counter__content-counter--units-text text-main">
                million
              </span> */}
            </h2>
            <h4 className="text-sub">Key Locations</h4>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default DigitalCounter
