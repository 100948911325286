/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useRef } from 'react'
import { animate } from 'framer-motion'
import './AnimatedCounter.scss'

type AnimatedCounterProps = {
  from: number
  to: number
  animationOptions?: any
  inView?: boolean
  styles?: string
}

function AnimatedCounter({
  from,
  to,
  animationOptions,
  inView,
  styles,
}: AnimatedCounterProps): JSX.Element {
  const ref = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    const element = ref.current
    let controls: any
    if (!element) {
      return
    }

    element.textContent = String(from)

    controls = animate(from, to, {
      duration: 1,
      ease: 'easeOut',
      ...animationOptions,
      onUpdate(value) {
        element.textContent = new Intl.NumberFormat('en-US').format(
          Number(value.toFixed()),
        )
      },
      delay: 0.7,
    })

    return (): void => {
      controls.stop()
    }
  }, [ref, from, to])

  return <span ref={ref} className={styles} />
}

export default AnimatedCounter
