export let mp4Index: number

export const moveMp4ToFront = (arr: any) => {
  // Find the index of the object with .mp4 in the featuredImage
  mp4Index = arr.findIndex((item: any) => item.featuredImage.includes('.mp4'))

  // If there's no object with .mp4, return the original array
  if (mp4Index === -1) return arr

  // Remove the object from its current position
  const [mp4Item] = arr.splice(mp4Index, 1)

  // Insert the object at the beginning of the array
  arr.unshift(mp4Item)

  return arr
}
