/* eslint-disable no-nested-ternary */
import React, { ReactElement, useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import HeroBanner from '../../components/HeroBanner/HeroBanner'
import Layout from '../../layouts/Layout/Layout'
import PassionImage from '../../assets/images/pursuit-of-passion.webp'
import HomePageBackground from '../../assets/images/home-page-background.png'
import Seo from '../../components/Seo/Seo'
import './Main.scss'
import { useInView } from 'react-intersection-observer'
import DigitalCounter from '../../components/DigitalCounter/DigitalCounter'

import {
  PropertyCardTypes,
  ArticleCardTypes,
  MainPropTypes,
} from './Main.types'

const ArticleCard = loadable(
  () => import('../../components/ArticleCard/ArticleCard'),
)
const PropertyCard = loadable(
  () => import('../../components/PropertyCard/PropertyCard'),
)
const SliderSection = loadable(
  () => import('../../components/SliderSection/SliderSection'),
)

const Main = ({
  data: {
    // heroBannerProperties: { nodes: heroBannerProperties },
    // heroBannerNewsAndEvents: { nodes: heroBannerNewsAndEvents },
    // heroBannerAnnouncements: { nodes: heroBannerAnnouncements },
    // heroBannerPursuitOfPassion: { nodes: heroBannerPursuitOfPassion },
    featuredProperties: { nodes: featuredProperties },
    featuredNewsAndEvents: { nodes: featuredNewsAndEvents },
    featuredPursuitOfPassion: { nodes: featuredPursuitOfPassion },
    SeoTags: { seoHomeTitle, seoHomeDescription },
  },
}: MainPropTypes): ReactElement => {
  const [bannerFeaturedData] = useState([
    ...featuredProperties.map((property) => ({
      type: property.propertyType,
      slug: property.slug,
      category: 'property',
      gallery: property.gallery.filter(
        (galleryItem: any) =>
          (!galleryItem.featured && galleryItem.type === 'mp4') ||
          galleryItem.type === 'video',
      ),
      priceRange: property.priceRange,
      floorAreaSize: property.floorAreaSize,
      featuredImage: property.featuredImage,
      optimizedFeaturedImg: property?.optimizedFeaturedImg || null,
    })),
  ])

  const { ref: counterRef, inView } = useInView({
    triggerOnce: false,
    threshold: 0.8,
  })
  const [isStick, setStick] = useState<boolean>(false)

  const sliderPropertyListMapper = useCallback(
    (property: PropertyCardTypes): ReactElement => (
      <PropertyCard key={property.slug} data={property} />
    ),
    [],
  )

  const sliderNewsAndEventsListMapper = useCallback(
    (event: ArticleCardTypes): ReactElement => (
      <ArticleCard
        key={event.slug}
        data={event}
        url={`/news-and-events/${event.slug}/`}
      />
    ),
    [],
  )

  const sliderPursuitOfPassionListMapper = useCallback(
    (blog: ArticleCardTypes): ReactElement => (
      <ArticleCard
        key={blog.slug}
        data={blog}
        url={`/pursuit-of-passion/${blog.slug}/`}
      />
    ),
    [],
  )

  return (
    <Layout
      isStick={isStick}
      setStick={setStick}
      navButtonVariant="burger"
      includeNewsLetter
    >
      <Seo
        title={seoHomeTitle || 'Avida Land'}
        ogMetaData={{
          title: seoHomeTitle || 'Avida Land',
          description:
            seoHomeDescription ||
            'Seeing unprecedented growth among the Filipino middle class, Ayala Land – from a portfolio of properties that were primarily high-end – expanded its scope to cater to the needs of this booming sector',
          image: '',
        }}
        jsonData={{
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment',
        }}
      />
      <HeroBanner
        featuredList={bannerFeaturedData}
        seoTitle={seoHomeTitle}
        SearchIsStick={isStick}
        setStick={setStick}
      />

      <div
        className="main-container"
        style={{
          background: `url('${HomePageBackground}') no-repeat scroll center top / contain`,
        }}
      >
        <div className="digital-counter-container" ref={counterRef}>
          {inView ? <DigitalCounter inView={inView} /> : null}
        </div>

        <SliderSection
          title="Featured Properties"
          subTitle="Check out some of our"
          description="Find a home that caters to your needs with Avida’s wide range of product offerings across the country."
          slideList={featuredProperties}
          listMapper={sliderPropertyListMapper}
          link="properties"
        />
        <SliderSection
          title="NEWS & EVENTS"
          description="Be in the know about anything and everything Avida—from news and updates, to events and other initiatives."
          slideList={featuredNewsAndEvents}
          listMapper={sliderNewsAndEventsListMapper}
          link="news-and-events"
        />
        <SliderSection
          titleImage={PassionImage}
          description="Be inspired by these stories of people pursuing their passions, of places to broaden your mind, and of holistic lifestyle interests for a life well lived."
          slideList={featuredPursuitOfPassion}
          listMapper={sliderPursuitOfPassionListMapper}
          link="pursuit-of-passion"
        />
      </div>
    </Layout>
  )
}

export default Main

export const pageQuery = graphql`
  query {
    heroBannerProperties: allProperty(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerPropertyFields
      }
    }
    heroBannerNewsAndEvents: allNewsAndEvents(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerNewsAndEventsFields
      }
    }
    heroBannerAnnouncements: allAnnouncement(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerAnnouncementFields
      }
    }
    heroBannerPursuitOfPassion: allPursuitOfPassion(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerPursuitOfPassionFields
      }
    }
    featuredProperties: allProperty(
      filter: { featured: { eq: true } }
      limit: 5
    ) {
      nodes {
        ...MainPagePropertyFields
      }
    }
    featuredNewsAndEvents: allNewsAndEvents(
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        ...MainPageNewsAndEventsFields
      }
    }
    featuredPursuitOfPassion: allPursuitOfPassion(
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        ...MainPagePursuitOfPassionFields
      }
    }
    SeoTags: homePageSeo {
      seoHomeDescription
      seoHomeTitle
    }
  }
`
